/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

header {
    width: 100%;
    background: #bdbbbb;
}

.full-width-green {
    width: 100%;
    background: #1a4137;
}

.full-width-white {
    width: 100%;
}

.paddingBetweenCols td {
    padding: 0 30px;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.alice-carousel ul li img {
    height: 400px;
    width: 534px;

}


.Modal {
    position: absolute;
    top: 10%;
    left: 10%;
    right: 90%;
    bottom: 90%;
    background-color: papayawhip;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rebeccapurple;
}
